import { memo } from 'react';
import { Box, Td, Text } from '@chakra-ui/react';
import { Gift, Mail, MapPin, Phone, User } from 'assets';
import { ActivityType } from 'types';

type Props = {
  type: ActivityType;
};

const optionsByActivityType: Record<
  ActivityType,
  {
    category: string;
    Icon: React.FC;
    text: string;
  }
> = {
  [ActivityType.SMS]: {
    category: 'SMS',
    Icon: Phone,
    text: 'SMS Sent',
  },
  [ActivityType.SMS_OPT_IN]: {
    category: 'SMS',
    Icon: Phone,
    text: 'SMS Opt-In',
  },
  [ActivityType.SMS_OPT_OUT]: {
    category: 'SMS',
    Icon: Phone,
    text: 'SMS Opt-Out',
  },
  [ActivityType.EMAIL]: {
    category: 'Email',
    Icon: Mail,
    text: 'Ad-Hoc Email',
  },
  [ActivityType.EMAIL_ONE_MONTH_RENEWAL_REMINDER]: {
    category: 'Email',
    Icon: Mail,
    text: 'One-Month Renewal Reminder',
  },
  [ActivityType.EMAIL_ONE_WEEK_RENEWAL_REMINDER]: {
    category: 'Email',
    Icon: Mail,
    text: 'One-Week Renewal Reminder',
  },
  [ActivityType.EMAIL_PATRON_START_OF_BIRTHDAY_MONTH]: {
    category: 'Email',
    Icon: Mail,
    text: 'Birthday Month Message',
  },
  [ActivityType.EMAIL_RENEWAL_REMINDER]: {
    category: 'Email',
    Icon: Mail,
    text: 'Ad-Hoc Renewal Reminder',
  },
  [ActivityType.VISIT_RECORDED]: {
    category: 'Visit',
    Icon: MapPin,
    text: 'Visit Recorded',
  },
  [ActivityType.REWARD_EARNED]: {
    category: 'Reward',
    Icon: Gift,
    text: 'Reward Earned',
  },
  [ActivityType.REWARD_REDEEMED]: {
    category: 'Reward',
    Icon: Gift,
    text: 'Reward Redeemed',
  },
  [ActivityType.MEMBERSHIP_CREATED]: {
    category: 'Membership',
    Icon: User,
    text: 'Membership Created',
  },
  [ActivityType.MEMBERSHIP_RENEWED]: {
    category: 'Membership',
    Icon: User,
    text: 'Membership Renewed',
  },
  [ActivityType.MEMBERSHIP_ACTIVATED]: {
    category: 'Membership',
    Icon: User,
    text: 'Membership Activated',
  },
};

function Activity({ type }: Props): JSX.Element | null {
  const { category, Icon, text } = optionsByActivityType[type];

  if (!Icon) {
    return null;
  }

  return (
    <>
      <Td>
        <Box
          alignItems="center"
          bg="gray.100"
          color="gray.500"
          display="inline-flex"
          fontSize="2xs"
          fontWeight="semibold"
          gap={1}
          mb={2}
          px={2}
          py={0.25}
          rounded="xl"
          textTransform="uppercase"
        >
          <Icon />
          <Text>{category}</Text>
        </Box>
      </Td>

      <Td>
        <Text fontSize="sm">{text}</Text>
      </Td>
    </>
  );
}

export default memo(Activity);
