import { signOut } from 'firebase/auth';
import { NavLink as ReactRouterLink, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { captureException, setUser } from '@sentry/react';
import posthog from 'posthog-js';
import { LogoPatch, InfoCircleIcon, LogOut, Settings } from 'assets';
import { useGetBrewery } from 'contexts';
import { ProductPlan } from 'types';
import FeatureFlagManager from './FeatureFlagManager';
import HelpModal from './HelpModal';
import NavigationItem from './NavigationItem';
import { auth } from '../../firebase';

const Navigation = (): JSX.Element => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [brewery] = useGetBrewery();
  const hasFreePlan = brewery?.plan === ProductPlan.FREE;

  async function handleClick() {
    try {
      await signOut(auth);
      posthog.reset();
      setUser(null);
      navigate('/');
    } catch (error) {
      captureException(error);
    }
  }

  return (
    <>
      <HelpModal isOpen={isOpen} onClose={onClose} />

      <Box
        display="flex"
        flexDirection="column"
        py={4}
        px={3}
        borderRight="1px"
        borderColor="gray.200"
        as="header"
        justifyContent="center"
        alignItems="center"
        w="80px"
      >
        <Box display="flex" fontSize="2xl" mb={8}>
          <Text>
            <LogoPatch />
          </Text>
        </Box>

        <Box display="flex" flexDirection="column" gridGap={3}>
          <NavigationItem page="clubs" />
          <NavigationItem page="patrons" />
          <NavigationItem page="activity" />
          <NavigationItem page="messages" />
          <NavigationItem page="mugs" />
        </Box>

        <Box
          mt="auto"
          mx="auto"
          display="flex"
          alignItems="center"
          flexDir="column"
          gap={4}
        >
          {hasFreePlan && (
            <Box border="1px" borderColor="gray.500" borderRadius="full" px={1}>
              <Text fontSize="2xs">Free Plan</Text>
            </Box>
          )}

          <Menu>
            <MenuButton>
              <Avatar size="xs" bg="gray.400" />
            </MenuButton>

            <MenuList>
              <Link to="/auth/settings" as={ReactRouterLink} width="100%">
                <MenuItem color="gray.500" icon={<Settings />}>
                  Settings
                </MenuItem>
              </Link>

              <MenuItem
                color="gray.500"
                icon={<InfoCircleIcon />}
                onClick={onOpen}
              >
                Help
              </MenuItem>

              <FeatureFlagManager />

              <MenuDivider />

              <MenuItem
                onClick={handleClick}
                color="gray.500"
                mt={2}
                icon={<LogOut />}
              >
                <Link variant="outline" color="gray.500">
                  Log Out
                </Link>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
    </>
  );
};

export default Navigation;
