import { Box, Spinner, Text } from '@chakra-ui/react';

interface Props {
  height?: string;
  text?: string;
  size?: string;
}

export default function Loader({
  height,
  size = 'lg',
  text = 'Loading...',
}: Props): JSX.Element {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={height}
    >
      <Box textAlign="center">
        <Spinner
          size={size}
          color="green.500"
          emptyColor="green.50"
          mb={4}
          speed="1.5s"
          thickness="3px"
        />
        <Text fontSize={size} fontWeight="medium">
          {text}
        </Text>
      </Box>
    </Box>
  );
}
