import {
  Box,
  Button,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  useToast,
  Switch,
} from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { updateBreweryFeatureFlag } from 'api';
import { Features } from 'types';
import { useGetBrewery } from 'contexts';

const FEATURE_FLAGS = Object.values(Features).map((feature) => ({
  featureFlag: feature,
  text: startCase(feature),
}));

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function FeatureFlagModal({
  isOpen,
  onClose,
}: Props): JSX.Element {
  const [brewery] = useGetBrewery();
  const toast = useToast();

  async function handleChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    try {
      await updateBreweryFeatureFlag(brewery.id, {
        feature: target.id as Features,
        enabled: target.checked,
      });

      toast({
        description: 'Feature flag updated.',
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      toast({
        description: 'Failed to update feature flag.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Feature Flags</Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody pt={0}>
          <Box display="grid" gap={2} mt={4}>
            {FEATURE_FLAGS.map(({ featureFlag, text }) => (
              <Box key={featureFlag} display="flex" alignItems="center" mb={2}>
                <Text flexGrow={1}>{text}</Text>
                <Switch
                  id={featureFlag}
                  onChange={handleChange}
                  isChecked={brewery.features?.includes(featureFlag)}
                />
              </Box>
            ))}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} size="sm" variant="outline">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
