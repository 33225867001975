import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { BreweryMessageType } from 'types';

export * from './firestore';

export {
  fetchClubSignupPageData,
  type IReturnData as IFetchClubSignupData,
} from './fetchClubSignupPageData';

export { processPatronSignup } from './processPatronSignup';

export const validateAdminUser = httpsCallable<
  { email: string },
  { isValid: boolean }
>(functions, 'validateAdminUser');

export const createStripeBillingPortalSession = httpsCallable<
  undefined,
  { sessionUrl: string }
>(functions, 'createStripeBillingPortalSession');

export const sendTestMessageToBreweryAdmin = httpsCallable<
  { breweryMessageType: BreweryMessageType },
  { message: string }
>(functions, 'sendTestMessageToBreweryAdmin');
