import { useEffect, useState } from 'react';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { useGetBrewery } from 'contexts';
import { Activity } from 'types';
import { db } from '../firebase';

type ReturnValue = [Activity[], boolean];

export default function useFetchActivities(): ReturnValue {
  const [brewery] = useGetBrewery();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchActivities() {
      if (!brewery) {
        return;
      }

      const activitiesCollection = collection(db, 'activities');
      const q = query(
        activitiesCollection,
        where('breweryId', '==', brewery.id),
        orderBy('createdAt', 'desc'),
      );

      const activitiesDocs = await getDocs(q);
      const activities: Activity[] = [];

      activitiesDocs.forEach((doc) => {
        const activity = {
          id: doc.id,
          ...doc.data(),
        } as Activity;

        activities.push(activity);
      });

      setActivities(activities);
      setIsLoading(false);
    }

    fetchActivities();
  }, [brewery]);

  return [activities, isLoading];
}
