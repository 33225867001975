import { Timestamp } from 'firebase/firestore';
import { defaultBreweryConfig } from '../../../../constants';
import { BreweryMessageType, IBrewery, ProductPlan } from 'types';

interface FormatBreweryDataParams {
  email: string;
  name: string;
  phoneNumber: string;
  uid: string;
  website: string;
}

export function formatBreweryData(
  params: FormatBreweryDataParams,
): Omit<IBrewery, 'id'> {
  const { email, name, phoneNumber, uid, website } = params;

  return {
    adminEmails: [email!],
    adminUid: uid,
    adminUids: [uid],
    config: {
      ...defaultBreweryConfig,
    },
    createdAt: Timestamp.now(),
    features: [],
    hasClubOverviewEmails: false,
    hasNewPatronEmails: false,
    name,
    messages: {
      [BreweryMessageType.PATRON_BIRTHDAY_EMAIL]: {
        enabled: false,
        value: '',
      },
      [BreweryMessageType.PATRON_ONE_MONTH_RENEWAL_REMINDER_EMAIL]: {
        enabled: false,
        value: '',
      },
      [BreweryMessageType.PATRON_ONE_WEEK_RENEWAL_REMINDER_EMAIL]: {
        enabled: false,
        value: '',
      },
      [BreweryMessageType.PATRON_SIGNED_UP_FOR_CLUB_EMAIL]: {
        enabled: false,
        value: '',
      },
    },
    phoneNumber,
    plan: ProductPlan.FREE,
    website,
  };
}

export function formatOnboardingSurveyData({
  hasMugClub = false,
  numberOfMembers = 0,
  hasRewardsProgram = false,
  clubChallenges = [],
}: {
  hasMugClub?: string | boolean;
  numberOfMembers?: string | number;
  hasRewardsProgram?: string | boolean;
  clubChallenges?: (string | number)[];
}): IBrewery['onboardingSurvey'] {
  return {
    hasMugClub: hasMugClub === 'true' || hasMugClub === true,
    numberOfMembers: Number(numberOfMembers),
    hasRewardsProgram:
      hasRewardsProgram === 'true' || hasRewardsProgram === true,
    clubChallenges: clubChallenges.map((challenge) => challenge.toString()),
  };
}
