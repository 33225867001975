import { Box } from '@chakra-ui/react';
import {
  OneMonthMembershipRenewal,
  OneWeekMembershipRenewal,
  BirthdayMessage,
  ClubSignUpInstructions,
  ClubSignUpConfirmation,
  PatronSignedUpForClubEmail,
  PatronEarnedReward,
} from './utils';
import { useMessagesPageContext } from '../../context';
import { BreweryConfigOptions } from '../../../../types';
import { useGetBrewery } from '../../../../contexts';

const editorByConfigOption: Record<BreweryConfigOptions, React.ElementType> = {
  [BreweryConfigOptions.birthdayMessageEnabled]: BirthdayMessage,
  [BreweryConfigOptions.clubSignUpInstructions]: ClubSignUpInstructions,
  [BreweryConfigOptions.clubSignUpConfirmation]: ClubSignUpConfirmation,
  [BreweryConfigOptions.patronOneMonthRenewalReminderEmail]:
    OneMonthMembershipRenewal,
  [BreweryConfigOptions.patronOneWeekRenewalReminderEmail]:
    OneWeekMembershipRenewal,
  [BreweryConfigOptions.patronSignedUpForClubEmailEnabled]:
    PatronSignedUpForClubEmail,
  [BreweryConfigOptions.patronEarnedRewardEmailEnabled]: PatronEarnedReward,
};

export default function MessageEditor(): JSX.Element {
  const [brewery] = useGetBrewery();
  const { breweryConfig, selectedConfig } = useMessagesPageContext();

  const ConfigEditor = editorByConfigOption[selectedConfig];
  const configEditorProps = {
    brewery,
    breweryConfig,
  };

  return (
    <Box px={6} py={4}>
      <ConfigEditor {...configEditorProps} />
    </Box>
  );
}
