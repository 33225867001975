import { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Switch,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { updateBreweryMessageStatus, updateBreweryMessageValue } from 'api';
import { useGetBrewery } from 'contexts';
import { BreweryMessageType } from 'types';
import MessageIcon from '../MessageIcon';
import SendTestEmailButton from './SendTestEmailButton';

type Props = {
  breweryMessageType: BreweryMessageType;
  description: string;
  label: string;
  messageType: 'email' | 'sms' | 'web';
  rows?: number;
};

export default function EmailMessageEditor({
  breweryMessageType,
  description,
  label,
  messageType,
  rows = 5,
}: Props): JSX.Element | null {
  const [brewery] = useGetBrewery();
  const toast = useToast();

  const { messages } = brewery;
  const message = messages?.[breweryMessageType];
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (message) {
      const formattedText = formatValueForTextarea(message.value);

      setIsEnabled(message.enabled);
      setValue(formattedText);
    }
  }, [message]);

  if (!message) {
    captureException(
      new Error(
        `Failed to load ${breweryMessageType} message for brewery: ${brewery.id}`,
      ),
    );
    return null;
  }

  async function handleChangeStatus(isEnabled: boolean) {
    try {
      await updateBreweryMessageStatus(brewery.id, {
        breweryMessageType,
        enabled: isEnabled,
      });

      setIsEnabled(isEnabled);

      toast({
        description: 'Successfully changed message status.',
        status: 'success',
        title: 'Success',
      });
    } catch {
      toast({
        description: 'Failed to change message status. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  async function handleSave() {
    setIsLoading(true);

    try {
      toast({
        description: 'Successfully saved message.',
        status: 'success',
        title: 'Success',
      });

      await updateBreweryMessageValue(brewery.id, {
        breweryMessageType,
        value: formatValueForDatabase(value),
      });
    } catch {
      toast({
        description: 'Failed to save message. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box maxW={640}>
      <Box mb={3}>
        <FormLabel alignItems="center" display="flex" gap={1} mb={1}>
          <MessageIcon messageType={messageType} />
          {label}
        </FormLabel>
        <Text mb={3} color="gray.500" fontSize="sm">
          {description}
        </Text>
      </Box>

      <FormControl mb={4}>
        <FormLabel fontSize="sm" mb={1}>
          Enable Message
        </FormLabel>

        <Switch
          isChecked={isEnabled}
          colorScheme="green"
          onChange={({ target }) => handleChangeStatus(target.checked)}
        />
      </FormControl>

      <FormLabel fontSize="sm" mb={1}>
        Message Content
      </FormLabel>

      <Box
        pl={4}
        py={2}
        bg="gray.50"
        border="1px solid"
        borderBottom="none"
        borderColor="gray.200"
        roundedTop="md"
      >
        <Text>👋 Hello PATRON_FIRST_NAME,</Text>
      </Box>

      <Textarea
        onChange={({ target }) => setValue(target.value)}
        rounded="none"
        rows={rows}
        value={value}
      />

      <Box
        pl={4}
        py={2}
        bg="gray.50"
        border="1px solid"
        borderColor="gray.200"
        borderTop="none"
        roundedBottom="md"
      >
        <Text mb={4}>Cheers,</Text>
        <Text>The team at {brewery.name}</Text>
      </Box>

      <Box display="flex" gap={2} justifyContent="flex-end" mt={4}>
        <SendTestEmailButton breweryMessageType={breweryMessageType} />

        <Button isLoading={isLoading} onClick={handleSave} size="sm">
          Save Message
        </Button>
      </Box>
    </Box>
  );
}

function formatValueForTextarea(value: string): string {
  return value
    .replace(/\\n/g, '\n')
    .split('\n')
    .map((line) => line.trimStart())
    .join('\n');
}

function formatValueForDatabase(value: string): string {
  return value
    .split('\n')
    .map((line) => line.trimEnd())
    .join('\\n');
}
