import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import {
  ActivityPage,
  BeersPage,
  ClubPage,
  ClubsPage,
  MessagesPage,
  MugsPage,
  NotFoundPage,
  PatronPage,
  PatronsPage,
  SettingsPage,
} from './pages';
import { FullscreenLoader } from './components';
import { BreweryProvider, useGetBrewery } from './contexts';
import { auth } from './firebase';

function AuthenticatedRoutes(): JSX.Element {
  const [brewery, isLoading] = useGetBrewery();
  const navigate = useNavigate();

  if (isLoading) {
    return <FullscreenLoader />;
  }

  if (!brewery && !isLoading) {
    navigate('/get-started');
  }

  return (
    <Routes>
      <Route path="/activity" element={<ActivityPage />} />
      <Route path="clubs" element={<ClubsPage />} />
      <Route path="clubs/:uid">
        <Route path="" element={<ClubPage />} />
        <Route path=":pane" element={<ClubPage />} />
      </Route>
      <Route path="patrons" element={<PatronsPage />} />
      <Route path="patrons/:uid" element={<PatronPage />} />
      <Route path="messages" element={<MessagesPage />} />
      <Route path="mugs" element={<MugsPage />} />
      <Route path="beers" element={<BeersPage />} />
      <Route path="settings" element={<SettingsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default function AuthenticatedApp(): JSX.Element {
  const navigate = useNavigate();
  const { currentUser } = auth;

  useEffect(() => {
    if (!currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  return (
    <BreweryProvider userUid={currentUser?.uid}>
      <AuthenticatedRoutes />
    </BreweryProvider>
  );
}
