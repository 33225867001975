import { DocumentReference, Timestamp } from 'firebase/firestore';

export interface Activity {
  id: string;
  breweryId: string;
  createdAt: Timestamp;
  clubId?: string;
  patronId: string;
  type: ActivityType;
  referenceId?: string;
}

export enum ActivityType {
  SMS = 'sms',
  SMS_OPT_IN = 'smsOptIn',
  SMS_OPT_OUT = 'smsOptOut',
  EMAIL = 'email',
  EMAIL_ONE_MONTH_RENEWAL_REMINDER = 'emailOneMonthRenewalReminder',
  EMAIL_ONE_WEEK_RENEWAL_REMINDER = 'emailOneWeekRenewalReminder',
  EMAIL_PATRON_START_OF_BIRTHDAY_MONTH = 'emailPatronStartOfBirthdayMonth',
  EMAIL_RENEWAL_REMINDER = 'emailRenewalReminder',
  VISIT_RECORDED = 'visitRecorded',
  REWARD_EARNED = 'rewardEarned',
  REWARD_REDEEMED = 'rewardRedeemed',
  MEMBERSHIP_CREATED = 'membershipCreated',
  MEMBERSHIP_RENEWED = 'membershipRenewed',
  MEMBERSHIP_ACTIVATED = 'membershipActivated',
}

export interface IBeer {
  abv: string;
  description?: string;
  id: string;
  isActive: boolean;
  name: string;
  style: string;
}

export interface IBrewery {
  id: string;
  adminEmails: string[];
  adminUid: string;
  adminUids: string[];
  config: IBreweryConfig;
  createdAt: Timestamp;
  features?: Features[];
  hasClubOverviewEmails: boolean;
  hasNewPatronEmails: boolean;
  messages?: BreweryMessages;
  name: string;
  onboardingSurvey?: {
    hasMugClub?: boolean;
    numberOfMembers?: number;
    hasRewardsProgram?: boolean;
    clubChallenges?: string[];
  };
  phoneNumber: string;
  plan: ProductPlan;
  slug?: string;
  stripeCustomerId?: string;
  website: string;
}

export enum ProductPlan {
  FREE = 'free',
  STARTER = 'starter',
  CORE = 'core',
  COMPLETE = 'complete',
}

export enum BreweryConfigOptions {
  birthdayMessageEnabled = 'birthdayMessageEnabled',
  clubSignUpConfirmation = 'clubSignUpConfirmation',
  clubSignUpInstructions = 'clubSignUpInstructions',
  patronEarnedRewardEmailEnabled = 'patronEarnedRewardEmailEnabled',
  patronOneMonthRenewalReminderEmail = 'patronOneMonthRenewalReminderEmail',
  patronOneWeekRenewalReminderEmail = 'patronOneWeekRenewalReminderEmail',
  patronSignedUpForClubEmailEnabled = 'patronSignedUpForClubEmailEnabled',
}

export enum BreweryMessageType {
  PATRON_BIRTHDAY_EMAIL = 'patronBirthdayEmail',
  PATRON_ONE_MONTH_RENEWAL_REMINDER_EMAIL = 'patronOneMonthRenewalReminderEmail',
  PATRON_ONE_WEEK_RENEWAL_REMINDER_EMAIL = 'patronOneWeekRenewalReminderEmail',
  PATRON_SIGNED_UP_FOR_CLUB_EMAIL = 'patronSignedUpForClubEmail',
}

export interface BreweryMessage {
  enabled: boolean;
  value: string;
}

export interface BreweryMessages {
  [BreweryMessageType.PATRON_BIRTHDAY_EMAIL]: BreweryMessage;
  [BreweryMessageType.PATRON_ONE_MONTH_RENEWAL_REMINDER_EMAIL]: BreweryMessage;
  [BreweryMessageType.PATRON_ONE_WEEK_RENEWAL_REMINDER_EMAIL]: BreweryMessage;
  [BreweryMessageType.PATRON_SIGNED_UP_FOR_CLUB_EMAIL]: BreweryMessage;
}

export interface IBreweryConfig {
  [BreweryConfigOptions.birthdayMessageEnabled]: boolean;
  [BreweryConfigOptions.clubSignUpConfirmation]: string;
  [BreweryConfigOptions.clubSignUpInstructions]: string;
  [BreweryConfigOptions.patronEarnedRewardEmailEnabled]: boolean;
  [BreweryConfigOptions.patronOneMonthRenewalReminderEmail]: string;
  [BreweryConfigOptions.patronOneWeekRenewalReminderEmail]: string;
  [BreweryConfigOptions.patronSignedUpForClubEmailEnabled]: boolean;
}

export enum Features {
  BENEFITS_TRACKING = 'benefits_tracking',
  CUSTOM_MESSAGES = 'custom_messages',
}

export enum ClubStatus {
  ACTIVE = 'active',
  COMPLETE = 'complete',
  DRAFT = 'draft',
}

export interface IClub {
  id: string;
  benefits: string[];
  breweryId: string;
  breweryRef: DocumentReference;
  capacity?: number | null;
  cost?: number | null;
  createdAt: Timestamp;
  createdBy: string;
  customQuestions?: string | null;
  description?: string;
  endDate: Timestamp | null;
  hasMugNumbers?: boolean;
  hasPatronApp?: boolean;
  hasPatronMugNumberSelection?: boolean;
  hasRenewalReminders?: boolean;
  hasTShirts?: boolean;
  isActive?: boolean;
  membershipDurationMonths?: number | null;
  name: string;
  notes?: string;
  registrationEndDate?: Timestamp | null;
  renewalCost?: number | null;
  rewards: IClubReward[];
  startDate: Timestamp | null;
  status: ClubStatus;
  terms?: string | null;
}

export interface IClubReward {
  description: string;
  id: string;
  threshold: number;
}

export interface Mug {
  clubId: string;
  number: number;
  patron?: IPatron;
}

export interface IPatron {
  id: string;
  archivedAt: Timestamp | null;
  birthdayMonth?: string | null;
  breweryId: string;
  breweryRef: DocumentReference;
  canReceiveSms: boolean;
  clubs: string[];
  createdAt: Timestamp;
  email: string;
  firstName: string;
  isActive?: boolean;
  lastName: string;
  lastUpdatedAt?: Timestamp | null;
  membershipActivatedAt?: string | null;
  membershipExpiresAt?: string | null;
  memberSince?: number | null;
  mugNumber?: number | null;
  notes?: {
    [key: string]: string | null;
  };
  phoneNumber: string;
  shirtSize?: string | null;
}

export interface IPatronReward {
  id: string;
  breweryId?: string;
  breweryRef: DocumentReference;
  clubId?: string;
  clubRef: DocumentReference;
  createdAt: Timestamp;
  description: string;
  patronId?: string;
  patronRef: DocumentReference;
  redeemed: boolean;
  redeemedAt: Timestamp | null;
  rewardId?: string;
  threshold: number;
}

export interface IMembership {
  id: string;
  activatedAt: Timestamp | null;
  breweryId: string;
  clubId: string;
  createdAt: Timestamp | null;
  expiresAt: Timestamp | null;
  isActive: boolean;
  patronId: string;
}

export interface IRecord {
  id: string;
  bartender: {
    name: string;
    ref: DocumentReference;
  };
  breweryId?: string;
  clubId?: string;
  clubRef: DocumentReference;
  createdAt: Timestamp;
  patronId?: string;
  patronRef: DocumentReference;
}

export interface IBartender {
  id: string;
  archivedAt: Timestamp | null;
  breweryRef: DocumentReference;
  createdAt: Timestamp;
  isActive: boolean;
  name: string;
}

export interface ITrackedBenefit {
  id: string;
  benefit: string;
  breweryId: string;
  clubId: string;
  createdAt: Timestamp;
  createdBy: string;
  patronId: string;
}
