import { memo } from 'react';
import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { Flag } from 'assets';
import { isDevelopment, isStaging } from 'utils';
import FeatureFlagModal from './FeatureFlagModal';

function FeatureFlagManager(): JSX.Element | null {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isDevelopment() || !isStaging()) {
    return null;
  }

  return (
    <>
      <FeatureFlagModal isOpen={isOpen} onClose={onClose} />
      <MenuItem color="gray.500" icon={<Flag />} onClick={onOpen}>
        Feature Flags
      </MenuItem>
    </>
  );
}

export default memo(FeatureFlagManager);
