import { useEffect, useState } from 'react';
import { useGetBrewery } from '../contexts';
import { ProductPlan, Features as FeatureFlags } from '../types';

type Features = {
  hasClubRewards: boolean;
  hasCustomMessages?: boolean;
};

export default function useGetFeatures(): Features {
  const [brewery] = useGetBrewery();
  const [features, setFeatures] = useState<Features>({
    hasClubRewards: false,
    hasCustomMessages: false,
  });

  useEffect(() => {
    if (!brewery) {
      return;
    }

    const { features: featureFlags, plan } = brewery;

    const hasCustomMessages = featureFlags?.includes(
      FeatureFlags.CUSTOM_MESSAGES,
    );
    const hasClubRewards = getHasClubRewards(plan);

    setFeatures({
      hasClubRewards,
      hasCustomMessages,
    });
  }, [brewery]);

  return features;
}

function getHasClubRewards(plan: ProductPlan): boolean {
  return plan === ProductPlan.CORE || plan === ProductPlan.COMPLETE;
}
