import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { EmptyState, Layout, LoadablePatronName, Loader } from 'components';
import { useFetchActivities } from 'hooks';
import Activity from './Activity';

export default function ActivityPage(): JSX.Element {
  const [activities, isLoading] = useFetchActivities();
  const renderActivitiesTable = Boolean(activities.length > 0);
  const renderEmptyState = Boolean(!isLoading && !activities.length);

  return (
    <Layout
      pageDescription="View messaging activity across your clubs and patrons."
      pageHeading="Activity"
    >
      {isLoading && (
        <Loader height="calc(100vh - 106px)" text="Loading activity..." />
      )}

      {renderEmptyState && (
        <Box alignItems="center" display="flex" height="calc(100vh - 106px)">
          <EmptyState
            heading="No Activities Found"
            description="Member activity will appear when available."
          />
        </Box>
      )}

      {renderActivitiesTable && (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Activity Type</Th>
                <Th>Activity Details</Th>
                <Th>Patron</Th>
              </Tr>
            </Thead>
            <Tbody>
              {activities.map((activity) => (
                <Tr key={activity.id}>
                  <Td fontSize="sm">
                    {activity.createdAt.toDate().toLocaleString()}
                  </Td>
                  <Activity type={activity.type} />
                  <Td>
                    <LoadablePatronName id={activity.patronId} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Layout>
  );
}
