import { useState } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { useGetBrewery } from 'contexts';
import { BreweryMessageType } from 'types';
import { sendTestMessageToBreweryAdmin } from 'api';

type Props = {
  breweryMessageType: BreweryMessageType;
};

export default function SendTestEmailButton({
  breweryMessageType,
}: Props): JSX.Element {
  const [brewery] = useGetBrewery();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleClick() {
    setIsLoading(true);

    try {
      await sendTestMessageToBreweryAdmin({ breweryMessageType });

      toast({
        description: `Sent test message to ${brewery.adminEmails[0]}.`,
        status: 'success',
        title: 'Success',
      });
    } catch {
      toast({
        description: 'Failed to send test message. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      isLoading={isLoading}
      onClick={handleClick}
      size="sm"
      variant="ghost"
    >
      Send Test
    </Button>
  );
}
