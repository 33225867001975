import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Box, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react';
import { useFetchPatron } from 'hooks';

type Props = {
  id: string;
};

function LoadablePatronName({ id }: Props): JSX.Element {
  const [patron, isLoading] = useFetchPatron(id);

  const fullName = patron && `${patron.firstName} ${patron.lastName}`;

  return (
    <Link to={`/auth/patrons/${id}`}>
      <Box display="flex" alignItems="center" gap={2}>
        <SkeletonCircle isLoaded={!isLoading} size="24px">
          {fullName && <Avatar name={fullName} size="xs" />}
        </SkeletonCircle>

        <Box>
          <Skeleton isLoaded={!isLoading}>
            <Text fontSize="xs" fontWeight="medium">
              {fullName}
            </Text>
          </Skeleton>

          <Skeleton isLoaded={!isLoading}>
            <Text fontSize="2xs" color="gray.600">
              {patron && patron.email}
            </Text>
          </Skeleton>
        </Box>
      </Box>
    </Link>
  );
}

export default memo(LoadablePatronName);
